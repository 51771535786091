<template>
    <div class="bg-white">
        <div class="tab row text-center p-2 m-0">
            <div class="col btn-hr-tab" @click="showList(1, 'salary')">入账明细</div>
            <div class="col btn-hr-tab" @click="showList(2, 'withdrawal')">提现记录</div>
        </div>
        <div class="p-2 border-top" v-if="showNum == 1">
            <table class="w-100">
                <tr class="text-center fw-bold">
                    <th>类 型</th>
                    <th>发生时间</th>
                    <th>入账时间</th>
                    <th>金 额</th>
                </tr>
                <tbody class="overflow-auto">
                    <tr class="text-center fs-7" v-for="(items, index) in salaryData" :key="index">
                        <td>{{ items.type }}</td>
                        <td>{{ items.date }}</td>
                        <td>{{ items.created_time }}</td>
                        <td>{{ items.money }}</td>
                    </tr>
                </tbody>
                
            </table>
        </div>
        <div class="p-3 border-top" v-if="showNum == 2">
             <table class="w-100">
                <tr class="text-center fw-bold">
                    <th>日 期 </th>
                    <th>金 额</th>
                    <th>余 额</th>
                    <th>备 注</th>
                </tr>
                <tbody class="overflow-auto">
                    <tr class="text-center fs-7" v-for="(items, index) in withdrawalData" :key="index">
                        <td>{{ items.date }}</td>
                        <td>{{ items.money }}</td>
                        <td>{{ items.balance }}</td>
                        <td>{{ items.remarks }}</td>
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
const showNum = ref(0)
function showList(key) {
    showNum.value = key
}
const a = 20
const salaryData = ref([])
for(let i=0; i<a; i++){
    salaryData.value.push(
        {
            id: 100,
            type: '日结工资', // 入账类型
            date: '20220223', //产生日期
            money: 200.89, //入账金额
            created_time: '20220323 18:20:22' //入账日期
        }
    )
}

const withdrawalData = ref([])
for(let i=0; i<a; i++){
    withdrawalData.value.push(
        {
            id: 100,
            date: '20220223', //提现日期
            money: 200.89, //提现金额
            balance: 1.89, //账户余额
            remarks: '提现到微信钱包' //备注
        }
    )
}
</script>

<style>

</style>