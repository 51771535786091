<template>
   <Userheader :money="data.countMoney"></Userheader>
   <div id="income">
       <Top></Top>
       <!-- 提现 -->
        <div class="row bg-white my-2 mx-0 py-2">
            <div>
                <p class="mb-0 text-danger">￥ {{ data.successWithdrawal }}</p>
                <p class="mb-0">已成功提现(元)</p>
            </div>
            <div class="border-top mt-2 py-2 d-flex align-items-center">
                <div class="me-auto">
                    <p class="mb-0 text-danger">￥ {{ data.countMoney }}</p>
                    <p class="mb-0">可提现(元)</p>
                </div>
                <div>
                    <router-link class="btn btn-sm btn-outline-success" to="/user/withdrawal">提 现</router-link>
                </div>
            </div>
        </div>
       <List></List>
   </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Axios from 'axios'
import Userheader from '@/components/user/Header.vue'
import List from '@/components/user/income/list'
import Top from '@/components/user/income/top'


const data = ref({
    countMoney: 10000.88, //账户余额
    successWithdrawal: 8888.88 //已成功提现金额
})

onMounted(() => {
    Axios.get('http://localhost:5000/user/balance').then((response) => {
        if(response.data.code == 0) {
            data.value = response.data.data
        }
    })
    const el = document.getElementById('qrcode')
    el.style.height = '58px'
    el.style.width = '100px'
    el.style.margin = '20px 0 0 0'
})

</script>

<style>

</style>