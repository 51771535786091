<template>
    <div class="row bg-white my-2 mx-0 py-2">
        <div class="text-center col fs-8 px-1">
            <p class="mb-1">{{ 5888 }}￥</p>
            <p class="mb-1">上月收入</p>
        </div>
        <div class="text-center col fs-8 px-1">
            <p class="mb-1">{{ 100 }}d</p>
            <p class="mb-1">上班天数</p>
        </div>
        <div class="text-center col fs-8 px-1">
            <p class="mb-1">{{ 1000 }}h</p>
            <p class="mb-1">打卡时长</p>
        </div>
        <div class="text-center col fs-8 px-1">
            <p class="mb-1">{{ 6000 }}￥</p>
            <p class="mb-1">预计收入</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>